import { __decorate } from "tslib";
import { core } from "@/store/modules/core";
import { indicatorReferencesModule } from "@/store/modules/indicator-references";
import { Component, Prop, Vue } from "vue-property-decorator";
import AppBtn from "./shared/ui/AppBtn.vue";
let Landing = class Landing extends Vue {
    get items() {
        return indicatorReferencesModule.indicatorReferences;
    }
    goTo(indicatorId) {
        core.setIndicatorId(indicatorId);
        this.$router.push({ name: "Overview" });
    }
};
__decorate([
    Prop({ default: "18px" })
], Landing.prototype, "width", void 0);
Landing = __decorate([
    Component({
        components: { AppBtn },
    })
], Landing);
export default Landing;
